import React, { FC, useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap';
import { changePassword } from './logic';

interface ChangePasswordModalProps {
  twoFaData: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  loading: boolean;
}

export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  twoFaData,
  setIsOpen,
  isOpen,
  loading
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    changePassword({ twoFaData, newPassword, setIsOpen });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className='modal-dialog-centered'>
      <ModalBody className='text-center'>
        <form className='auth-login-form' onSubmit={(e) => onSubmit(e)}>
          <div className='mb-3'>
            <div className='avatar-lg mx-auto'>
              <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
                <i className='ri-error-warning-fill '></i>
              </div>
            </div>
          </div>

          <h4>Change Password</h4>
          <p className='mb-5'>
            Entered password is auto generated by the system, please set new
            password
          </p>

          <div className='mb-3'>
            <Label className='form-label' htmlFor='password-input'>
              Password
            </Label>
            <div className='position-relative auth-pass-inputgroup mb-3'>
              <Input
                name='password'
                placeholder='Enter Password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={passwordShow ? 'text' : 'password'}
                className='form-control pe-5'
              />

              <button
                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                type='button'
                id='password-addon'
                onClick={() => setPasswordShow(!passwordShow)}>
                <i className='ri-eye-fill align-middle'></i>
              </button>
            </div>
          </div>
          <div className='mt-3'>
            <Button
              color='success'
              className='w-100'
              type='submit'
              disabled={Number(newPassword) < 6}>
              {loading ? <Spinner size='sm' className='me-2' /> : null}
              Confirm
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
