import { axiosAuthInstance } from '../helpers/api_helper';

export const apiSignIn = async (data: any) => {
  return axiosAuthInstance.post('/authorization/signin', data);
};

export const apiChangePassword = async (twoFaData: any, newPassword: any) => {
  return axiosAuthInstance.put(`/authorization/signin/password/change`, {
    code: twoFaData.code,
    password: newPassword
  });
};

export const enableTwoStepVerificationApi = async (twoFaData: any) => {
  return axiosAuthInstance.post(`/authorization/security/2fa/enable`, {
    code: twoFaData.code
  });
};

export const confirmTwoStepVerificationApi = async (
  twoFaData: any,
  code: any
) => {
  return axiosAuthInstance.put(`/authorization/security/2fa/enable/confirm`, {
    code: twoFaData.code,
    twoFACode: code
  });
};
