import { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  formatUSD,
  handleValidDate,
  usdFormatter
} from '../../../helpers/helpers';
import { InfoSmallIcon } from '../../../styles/Icons';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Table } from 'reactstrap';
import Countdown from 'react-countdown';

const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 7 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const CountdownComponent = (dateIso: any) => {
  const date = new Date(dateIso.dateIso);
  const timestamp = date.getTime();

  return (
    <Countdown
      date={timestamp}
      renderer={({ days, hours, minutes }) => (
        <>
          <b>
            {days}D {hours}H {minutes}M
          </b>
        </>
      )}
    />
  );
};

export const columns = [
  {
    id: 1,
    Header: 'Name',
    accessor: 'name',
    Cell: (cell: any) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={cell.row.original.image}
            alt='token'
            style={{ width: '40px', marginRight: 10 }}
          />
          <div>{cell?.value}</div>
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Token Amount',
    accessor: 'tokenAmount',
    Cell: (cell: any) => {
      const [isRight, setIsRight] = useState(false);
      const toggleRightCanvas = () => {
        setIsRight(!isRight);
      };
      const { hasActiveLicence } = useAppSelector((state) => state.airdrops);

      return (
        <div
          style={{
            color: hasActiveLicence ? 'black' : '#f6b84b',
            cursor: hasActiveLicence ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (hasActiveLicence) {
              toggleRightCanvas();
            }
          }}>
          {cell?.value !== '/' ? formatUSD(Number(cell?.value)) : '/'}
          <Offcanvas
            isOpen={isRight}
            toggle={toggleRightCanvas}
            id='offcanvasRight'
            direction='end'>
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              id='offcanvasRightLabel'>
              KIMBA Airdrops Details
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className='table-responsive'>
                <Table className='table-striped table-nowrap align-middle mb-0'>
                  <thead>
                    <tr>
                      <th scope='col'>Token</th>
                      <th scope='col'>Amount</th>
                      <th scope='col'>Unlocked In</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cell.row.original?.airdrops?.map(
                      (airdrop: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{cell.row.original.name}</td>
                            <td>{formatUSD(Number(airdrop.tokenAmount))}</td>
                            <td>
                              {' '}
                              <CountdownComponent
                                dateIso={airdrop.unlockDate}
                              />{' '}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      );
    }
  },

  {
    id: 3,
    Header: 'Lock',
    accessor: 'lock',
    Cell: (cell: any) => {
      const [isRight, setIsRight] = useState(false);
      const toggleRightCanvas = () => {
        setIsRight(!isRight);
      };
      const { hasActiveLicence } = useAppSelector((state) => state.airdrops);

      return (
        <div
          style={{ cursor: hasActiveLicence ? 'pointer' : 'default' }}
          onClick={() => {
            if (hasActiveLicence) {
              toggleRightCanvas();
            }
          }}>
          <InfoSmallIcon />
          <Offcanvas
            isOpen={isRight}
            toggle={toggleRightCanvas}
            id='offcanvasRight'
            direction='end'>
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              id='offcanvasRightLabel'>
              KIMBA Airdrops Details
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className='table-responsive'>
                <Table className='table-striped table-nowrap align-middle mb-0'>
                  <thead>
                    <tr>
                      <th scope='col'>Token</th>
                      <th scope='col'>Amount</th>
                      <th scope='col'>Unlocked In</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cell.row.original?.airdrops?.map(
                      (airdrop: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{cell.row.original.name}</td>
                            <td>{formatUSD(Number(airdrop.tokenAmount))}</td>
                            <td>
                              {' '}
                              <CountdownComponent
                                dateIso={airdrop.unlockDate}
                              />{' '}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      );
    }
  },
  {
    id: 4,
    Header: 'Price',
    accessor: 'price',
    Cell: (cell: any) => {
      const { hasActiveLicence } = useAppSelector((state) => state.airdrops);
      return (
        <div
          style={{
            color: hasActiveLicence ? 'black' : '#f6b84b'
          }}>
          {cell?.value !== '/'
            ? formatterCurrency.format(Number(cell?.value))
            : '/'}
        </div>
      );
    }
  },
  {
    id: 5,
    Header: 'Total Value',
    accessor: 'totalValue',
    Cell: (cell: any) => {
      const { hasActiveLicence } = useAppSelector((state) => state.airdrops);
      return (
        <div
          style={{
            color: hasActiveLicence ? 'black' : '#f6b84b'
          }}>
          {cell?.value !== '/'
            ? formatterCurrency.format(Number(cell?.value))
            : '/'}
        </div>
      );
    }
  }
];
