import { ReactNode } from 'react';
import { URLSearchParamsInit } from 'react-router-dom';
import { CardHeader, Row, Spinner } from 'reactstrap';

interface PageHeaderProps {
  loading?: boolean;
  title: string;
  actions?: ReactNode;
  initialParams?: URLSearchParamsInit;
  setSearchParams?: any;
  clearButtonText?: string;
}

export const PageHeader = ({
  loading,
  title,
  actions,
  initialParams,
  setSearchParams,
  clearButtonText
}: PageHeaderProps) => (
  <CardHeader className='border-0'>
    <Row className='align-items-center gy-3'>
      <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
        <h5 className='card-title mb-0'>{title}</h5>
        {loading && <Spinner size='sm' className='primary' />}
      </div>
      <div className='col-sm-auto'>
        <div className='d-flex gap-3 flex-wrap align-items-center'>
          {actions}

          {initialParams && (
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => setSearchParams(initialParams)}>
              {!clearButtonText && (
                <i className='ri-filter-off-fill align-bottom me-1'></i>
              )}{' '}
              {clearButtonText ? clearButtonText : 'Clear Filters'}
            </button>
          )}
        </div>
      </div>
    </Row>
  </CardHeader>
);
