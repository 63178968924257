import { useEffect } from 'react';
import { Route, Routes, useMatch } from 'react-router';
import { Col, Nav, NavItem, Row, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getUserHubsSummary } from '../../slice';

import { NavLink } from 'react-router-dom';
import {
  dateDifferenceInDaysFromToday,
  formatDecimalNumber,
  formatUSD
} from '../../../../../helpers/helpers';
import { Tile } from '../../../../../Components/Custom/Tile';
import { BigWhale } from './sections/BigWhale';
import { Travel } from './sections/Travel';
import { Gaming } from './sections/Gaming';
import { Payment } from './sections/Payment';
import { Staking } from './sections/Staking';
import { Trading } from './sections/Trading';
import { FlipMe } from './sections/FlipMe';

const nestedPages = [
  {
    name: 'Trading',
    route: 'trading',
    component: Trading
  },
  {
    name: 'Gaming',
    route: 'gaming',
    component: Gaming
  },
  {
    name: 'Payment',
    route: 'payment',
    component: Payment
  },
  {
    name: 'Staking',
    route: 'staking',
    component: Staking
  },
  {
    name: 'BigWhale',
    route: 'big-whale',
    component: BigWhale
  },
  {
    name: 'Travel',
    route: 'travel',
    component: Travel
  },
  {
    name: 'FlipMe',
    route: 'flipme',
    component: FlipMe
  }
];

export const Hubs = () => {
  const dispatch = useAppDispatch();

  const { data, loading, walletSummary, lifetimeRank, qualifiedRank } =
    useAppSelector((state) => state.userDetails);

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    dispatch(getUserHubsSummary(Number(userId)));
  }, []);

  const EXPLORER_LINK = 'https://ulxscan.com';
  return (
    <div>
      <Col lg={12} className='justify-content-between d-flex mb-3'>
        <h4>Hubs</h4>
        <div className='text-end'>
          <p className='mb-0'>wULX WALLET ADDRESS</p>

          <a
            href={`${EXPLORER_LINK}/address/${walletSummary?.userAddress}`}
            target='_blank'
            rel='noreferrer'>
            <p>{walletSummary?.userAddress}</p>
          </a>
        </div>
      </Col>
      <h5>My Wallet Summary</h5>
      <Row>
        <Tile label='TOTAL PERSONAL ORDERS'>
          <>{formatUSD(walletSummary?.totalOrder)} </>
          <span className='text-muted fs-14'> USDT</span>
        </Tile>

        <Tile label='AVAILABLE IN WALLET'>
          {formatUSD(walletSummary?.availableBalance)}{' '}
          <span className='text-muted fs-14'>wULX</span>
        </Tile>

        <Tile label='AVAILABLE IN WALLET'>
          {formatDecimalNumber(
            walletSummary?.availableUsdtToWithdraw
              ? walletSummary?.availableUsdtToWithdraw
              : 0
          )}{' '}
          <span className='text-muted fs-14'>uUSDT</span>
        </Tile>
      </Row>
      <Row className='justify-content-end'></Row>
      <h5>My Membership Status</h5>
      <Row>
        <Tile label='BACK OFFICE SUBSCRIPTION'>
          {Number(dateDifferenceInDaysFromToday(data.licenceEndDate)) < 1 ? (
            'EXPIRED'
          ) : (
            <>
              {dateDifferenceInDaysFromToday(data.licenceEndDate)}
              <span className='text-muted fs-14'> DAYS LEFT</span>
            </>
          )}
        </Tile>
        <Tile label='CURRENT RANK'>{lifetimeRank}</Tile>
        <Tile label='QUALIFIED RANK'>{qualifiedRank}</Tile>
      </Row>

      <Nav
        className='nav-tabs-custom rounded  border-bottom-0 mb-4 mt-4'
        role='tablist'>
        {nestedPages.map((page, i) => (
          <NavItem key={i}>
            <NavLink to={page.route} className='nav-link'>
              <i className='fas fa-home'></i>

              {page.name}
            </NavLink>
          </NavItem>
        ))}

        {loading && (
          <div className='d-flex align-items-center'>
            <Spinner size='sm' className='primary' />
          </div>
        )}
      </Nav>

      <Routes>
        {nestedPages.map((page, i) => (
          <Route key={i} path={page.route} element={<page.component />} />
        ))}
      </Routes>
    </div>
  );
};
