import { Accordion, AccordionItem, Collapse, Row } from 'reactstrap';

import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { formatUSD } from '../../../../../../helpers/helpers';
import { getUserHubsPaymentInfo } from '../../../slice';
import { GamingHub } from '../GamingHub';
import { Tile } from '../../../../../../Components/Custom/Tile';

export const Payment = () => {
  const { payment } = useAppSelector((state) => state.userDetails);

  const [col, setCol] = useState(true);

  const t_col = () => {
    setCol(!col);
  };

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    if (payment !== null) return;
    dispatch(getUserHubsPaymentInfo(Number(userId)));
  }, []);

  return (
    <div>
      {' '}
      <h5>My Payment HUB NFTs summary</h5>
      <Row>
        <Tile label='TOTAL PAYMENT HUB FT ORDERS'>
          {formatUSD(payment?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='REVENUE SHARE POINTS'>
          {formatUSD(payment?.summary?.totalRsp)}{' '}
          <span className='text-muted fs-14'>RSP</span>
        </Tile>
        <Tile label='TOTAL ORDERS WITH AUTOSTAKE ON'>
          {formatUSD(payment?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
      </Row>
      <Accordion open={'3'}>
        <AccordionItem>
          <h2 className='accordion-header' id='headingTwo'>
            <button
              className={classnames('accordion-button', { collapsed: !col })}
              type='button'
              onClick={t_col}
              style={{ cursor: 'pointer' }}>
              Payment HUB NFTs
            </button>
          </h2>
          <Collapse isOpen={col} className='accordion-collapse'>
            <div className='accordion-body'>
              {payment?.userPackages?.map((data, i) => (
                <GamingHub key={i} data={data} />
              ))}
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
