import { useEffect, FC } from 'react';

import { Row } from 'reactstrap';

import { useMatch } from 'react-router';

import { useAppSelector, useAppDispatch } from '../../../../../../../app/hooks';

import TableContainer from '../../../../../../../Components/Common/TableContainer';

import { Tile } from '../../../../../../../Components/Custom/Tile';

import { columns } from './columns';
import { getFinUpInfo } from '../../../../slice';
import { formatUSD } from '../../../../../../../helpers/helpers';
import { H3 } from '../../../../../../../styles/FontStyles';

export const FinUp: FC = () => {
  const dispatch = useAppDispatch();
  const { finUp } = useAppSelector((state) => state.userDetails);

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  const renderCoins = () => {
    if (finUp?.totalCoinsForToday !== undefined) {
      if (finUp.totalCoinsForToday > 0) {
        return (
          <Row>
            <Tile label='TOTAL COINS FOR TODAY'>
              <>{formatUSD(finUp?.totalCoinsForToday)} </>
              <span className='text-muted fs-14'> WILLY</span>
            </Tile>

            <Tile label='TOTAL COINS TILL TODAY'>
              {formatUSD(finUp?.totalCoinsTillToday)}{' '}
              <span className='text-muted fs-14'>WILLY</span>
            </Tile>
          </Row>
        );
      }
    }
  };

  const renderTable = () => {
    if (finUp?.finup !== undefined) {
      if (finUp.finup.length > 0) {
        return (
          <TableContainer
            columns={columns}
            data={finUp?.finup || []}
            divClass='table-responsive table-card mb-1'
            tableClass='align-middle table-nowrap'
            theadClass='table-light text-muted'
          />
        );
      } else {
        return <H3 center>No collected coins at the moment</H3>;
      }
    }
  };

  useEffect(() => {
    if (finUp !== null) return;
    dispatch(getFinUpInfo(Number(userId)));
  }, []);

  return (
    <>
      {renderCoins()}
      {renderTable()}
    </>
  );
};
