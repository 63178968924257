import React, { useEffect } from 'react';
import { NavLink, Route, Routes, useMatch } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  Spinner
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import userDummy from '../../../assets/images/users/user-dummy-img.jpg';
import { setDocumentTitle } from '../../../helpers/helpers';
import UserDetailsActions from './actions';
import { Comments } from './sections/Comments/Comments';
import { Hubs } from './sections/Hubs/Hubs';
import { KycPreview } from './sections/KycPreview/KycPreview';
import { PersonalDetails } from './sections/PersonalDetails/PersonalDetails';
import { Unilevel } from './sections/Unilevel/Unilevel';
import {
  getUserDetails,
  removeUserFromState,
  cleatUserDetailsState
} from './slice';

import EarningsPage from '../Earnings';
import { DubaiEvent } from './sections/DubaiEvent/DubaiEvent';
// import { BootcampEvent } from './sections/Bootcamp/BootcampEvent';
// import { CruisesEvent } from './sections/CruisesEvent/CruisesEvent';
import { Invoices } from './sections/Invoices/Invoices';
import { PointsCards } from './sections/PointsCards/PointsCards';
import { Ranking } from './sections/Ranking/Ranking';
import { TopUp } from './sections/TopUp/TopUp';
import { Transactions } from './sections/Transactions/Transactions';
import { Wallets } from './sections/Wallets/Wallets';
import { Withdrawals } from './sections/Withdrawals/Withdrawals';
import { MemeCoins } from './sections/MemeCoins/MemeCoins';

const nestedPages = [
  {
    name: 'Personal Details',
    route: 'personal-details',
    component: PersonalDetails
  },
  { name: 'KYC', route: 'kyc-preview', component: KycPreview },
  { name: 'Hubs', route: 'hubs/*', component: Hubs },
  { name: 'Earnings', route: 'earnings', component: EarningsPage },
  { name: 'Comments', route: 'comments', component: Comments },
  { name: 'Unilevel', route: 'unilevel', component: Unilevel },
  { name: 'Ranking', route: 'ranking', component: Ranking },
  { name: 'Wallets', route: 'wallets', component: Wallets },
  { name: 'Invoices', route: 'invoices', component: Invoices },
  { name: 'Transactions', route: 'transactions', component: Transactions },
  { name: 'Withdrawals', route: 'withdrawals', component: Withdrawals },
  { name: 'Top Up', route: 'top-up', component: TopUp },
  { name: 'Dubai Event', route: 'dubai-event', component: DubaiEvent },
  // { name: 'Cruises Event', route: 'cruises-event', component: CruisesEvent },
  // {
  //   name: 'Bootcamp Event',
  //   route: 'bootcamp-event',
  //   component: BootcampEvent
  // },
  {
    name: 'Meme Coins',
    route: 'meme-coins/*',
    component: MemeCoins
  }
];

const PAGE_TITLE = 'User Details';

const UserDetailsPage = () => {
  const dispatch = useAppDispatch();
  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;
  const { loading, data } = useAppSelector((state) => state.userDetails);
  const earnings = useAppSelector((state) => state.earnings);

  useEffect(() => {
    dispatch(cleatUserDetailsState());
    dispatch(getUserDetails(Number(userId)));
    return () => {
      dispatch(removeUserFromState());
    };
  }, []);

  setDocumentTitle(PAGE_TITLE);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xxl={3}>
              <Card>
                <CardBody className='p-4'>
                  <div className='text-center'>
                    <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                      <img
                        src={data?.profileImageUrl || userDummy}
                        className='rounded-circle avatar-xl img-thumbnail user-profile-image'
                        alt='user-profile'
                      />
                    </div>
                    <h5 className='fs-16 mb-1'>ID: {data?.id}</h5>
                    <h5 className='fs-16 mb-1'>
                      {data?.name + ' ' + data?.surname}
                    </h5>
                    <p className='text-muted mb-2'>
                      {data?.userRank?.rank?.name}
                    </p>
                  </div>
                </CardBody>
              </Card>

              <UserDetailsActions userId={Number(userId)} />
            </Col>

            <Col xxl={9}>
              <PointsCards />
              <Card>
                <CardHeader className='d-flex'>
                  <Nav
                    className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                    role='tablist'>
                    {nestedPages.map((page, i) => (
                      <NavItem key={i}>
                        <NavLink
                          to={page.route}
                          className={`nav-link  ${
                            page.route === 'hubs/*' &&
                            window.location.pathname.startsWith(
                              `/users/${userId}/hubs`
                            ) &&
                            'active'
                          }  `}>
                          <i className='fas fa-home'></i>
                          {page.name}
                        </NavLink>
                      </NavItem>
                    ))}

                    {loading ||
                      (earnings.loading && (
                        <div className='d-flex align-items-center'>
                          <Spinner size='sm' className='primary' />
                        </div>
                      ))}
                  </Nav>
                </CardHeader>
                <CardBody className='p-4'>
                  <Routes>
                    {nestedPages.map((page, i) => (
                      <Route
                        key={i}
                        path={page.route}
                        element={<page.component />}
                      />
                    ))}
                  </Routes>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserDetailsPage;
