const localStorageNode = 'ultron-admin';
const localStorageThemeNode = 'ultron-admin-theme';

export const store = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const read = (key: string) => {
  return localStorage.getItem(key) ?? '1';
};

export const getTokens = () => {
  return JSON.parse(read(localStorageNode))?.token;
};

export const getAccessToken = () => {
  return JSON.parse(read(localStorageNode))?.token?.accessToken;
};

export const getRefreshToken = () => {
  return JSON?.parse(read(localStorageNode))?.token?.refreshToken;
};

export const getUserFromLS = () => {
  return JSON.parse(read(localStorageNode))?.user;
};

export const setNewAccessToken = (accessToken: string) => {
  let data = JSON.parse(read(localStorageNode));
  data.token.accessToken = accessToken;
  store(localStorageNode, JSON.stringify(data));
};

export const setLoginDataToLS = (data: any) => {
  store(localStorageNode, JSON.stringify(data));
};

export const removeLoginDataFromLS = () => {
  localStorage.removeItem(localStorageNode);
};

export const getTheme = () => {
  return JSON.parse(read(localStorageThemeNode));
};

export const setThemeToLS = (data: any) => {
  store(localStorageThemeNode, JSON.stringify(data));
};
