import { SearchInputFilter } from './SearchInputFilter';
import { SelectFilter } from './SelectFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { AmountFilter } from './AmountFilter';
import {
  INPUT_TYPE_AMOUNT,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../helpers/helpers';
import { CardBody, Col, Row } from 'reactstrap';

export const RenderFilters = ({
  filterInputs,
  searchParams,
  setSearchParams,
  colLg,
  colMd,
  colSm,
  noPagination = false
}: {
  filterInputs: object[];
  searchParams: URLSearchParams;
  setSearchParams: any;
  colLg?: number;
  colMd?: number;
  colSm?: number;
  noPagination?: boolean;
}) => {
  return (
    <CardBody className='border border-dashed border-end-0 border-start-0'>
      <Row>
        {filterInputs.map(({ type, options, param, placeholder }: any, i) => {
          if (type === INPUT_TYPE_SEARCH) {
            return (
              <Col lg={colLg || 3} md={colMd || 3} sm={colSm || 4} key={i}>
                <SearchInputFilter
                  param={param}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  placeholder={placeholder}
                />
              </Col>
            );
          }

          if (type === INPUT_TYPE_SELECT) {
            return (
              <Col lg={colLg || 3} md={colMd || 3} sm={colSm || 4} key={i}>
                <SelectFilter
                  param={param}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  options={options}
                  placeholder={placeholder}
                />
              </Col>
            );
          }

          if (type === INPUT_TYPE_DATE_RANGE) {
            return (
              <Col lg={colLg || 3} md={colMd || 3} sm={colSm || 4} key={i}>
                <DateRangeFilter
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  noPagination={noPagination}
                />
              </Col>
            );
          }

          if (type === INPUT_TYPE_AMOUNT) {
            return (
              <Col lg={colLg || 3} md={colMd || 3} sm={colSm || 4} key={i}>
                <AmountFilter
                  param={param}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  placeholder={placeholder}
                />
              </Col>
            );
          }
        })}
      </Row>
    </CardBody>
  );
};
