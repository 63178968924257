import { Accordion, AccordionItem, Collapse, Row } from 'reactstrap';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { formatUSD } from '../../../../../../helpers/helpers';
import {
  getUserHubsBigWhaleInfo,
  getUserHubsPaymentInfo,
  updateWillyCoinsSummary
} from '../../../slice';
import { GamingHub } from '../GamingHub';
import { Tile } from '../../../../../../Components/Custom/Tile';
import { BigWhaleHub } from '../BigWhaleHub';

export const BigWhale = () => {
  const { loading, bigWhale } = useAppSelector((state) => state.userDetails);

  const [col, setCol] = useState(true);

  const t_col = () => {
    setCol(!col);
  };

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    if (bigWhale !== null) return;
    dispatch(getUserHubsBigWhaleInfo(Number(userId)));
  }, [bigWhale]);

  useEffect(() => {
    if (!loading && bigWhale?.summary?.changePeriodInSeconds !== undefined) {
      const interval = setInterval(() => {
        dispatch(updateWillyCoinsSummary(bigWhale!.summary));
      }, bigWhale!.summary?.changePeriodInSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [bigWhale?.summary]);

  return (
    <div>
      {' '}
      <h5>My BigWhale Package summary</h5>
      <Row>
        <Tile label='Total BigWhale Orders'>
          {formatUSD(bigWhale?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='BigWhale Points'>
          {formatUSD(bigWhale?.summary?.totalBigWhalePoints)}{' '}
          <span className='text-muted fs-14'>BP</span>
        </Tile>
      </Row>
      <Row>
        <Tile label='TOTAL WILLY MEME COIN COLLECTION'>
          {formatUSD(bigWhale?.summary?.totalMemeCoins)}
          <span className='text-muted fs-14'> WILLY</span>
        </Tile>
        <Tile label='WILLY COLLECTED FROM BIGWHALE STRATEGYs'>
          {formatUSD(bigWhale?.summary?.memeCoinsFromBigWhale)}{' '}
          <span className='text-muted fs-14'>WILLY</span>
        </Tile>
        <Tile label='WILLY COLLECTED FROM "BRING 3 PROMOTION"'>
          {formatUSD(bigWhale?.summary?.memeCoinsFromBring3)}{' '}
          <span className='text-muted fs-14'>WILLY</span>
        </Tile>
      </Row>
      <Accordion open={'3'}>
        <AccordionItem>
          <h2 className='accordion-header' id='headingTwo'>
            <button
              className={classnames('accordion-button', { collapsed: !col })}
              type='button'
              onClick={t_col}
              style={{ cursor: 'pointer' }}>
              BigWhale Packages
            </button>
          </h2>
          <Collapse isOpen={col} className='accordion-collapse'>
            <div className='accordion-body'>
              {bigWhale?.userPackages?.map((data, i) => (
                <BigWhaleHub key={i} data={data} />
              ))}
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
