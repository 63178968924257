import { useEffect, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { handleValidDate } from '../../../../../helpers/helpers';
import Select from 'react-select';
import {
  toggleEditState,
  updateUserAddress,
  updateUserEmail,
  updateUserPersonalDetails
} from '../../slice';
import { UserDetailsType } from '../../types';
import { SeparatorWithButton } from './SeparatorWithButton';
import {
  CreationTypesEnum,
  RegistrationStatusEnum
} from '../../../Users/types';

import countries from '../../../../../helpers/countries';

export const PersonalDetails = () => {
  const [selectedCountry, setSelectedCountry] = useState({
    label: '',
    value: '',
    countryCode: ''
  });

  const countryList: any = [];

  countries?.forEach((country) => {
    countryList.push({
      label: country.name,
      value: country.code,
      countryCode: country.code
    });
  });

  const dispatch = useAppDispatch();

  const [detailsToCompare, setDetailsToCompare] =
    useState<UserDetailsType | null>(null);

  const { data, editState, qualifiedRank } = useAppSelector(
    (state) => state.userDetails
  );

  useEffect(() => {
    if (data) {
      setDetailsToCompare(data);
      setSelectedCountry({
        label: data?.address?.country,
        value: data?.address?.countryCode,
        countryCode: data?.address?.countryCode
      });
    }
  }, [data]);

  const handleUpdateUserEmail = () => {
    if (detailsToCompare === null) return;
    dispatch(updateUserEmail(detailsToCompare));
  };

  const handleUpdateUserDetails = () => {
    if (detailsToCompare === null) return;
    dispatch(updateUserPersonalDetails(detailsToCompare));
  };

  const handleUpdateAddress = () => {
    if (detailsToCompare === null) return;
    dispatch(updateUserAddress(detailsToCompare));
  };

  const handleChangeInput = (e: any) => {
    if (!detailsToCompare) return;
    const { name, value } = e.target;
    setDetailsToCompare((prev: any) => {
      const data = { ...prev };
      data[name as keyof typeof detailsToCompare] = value;
      return data;
    });
  };

  const handleChangeInputAddress = (e: any) => {
    if (!detailsToCompare) return;
    const { name, value } = e.target;
    setDetailsToCompare((prev: any) => {
      const data = { ...prev };
      const address = { ...prev.address };
      address[name as keyof typeof detailsToCompare] = value;
      data.address = address;

      return data;
    });
  };

  const handleChangeCountry = (e: any) => {
    if (!detailsToCompare) return;

    setSelectedCountry(e);

    setDetailsToCompare((prev: any) => {
      const data = { ...prev };
      const address = { ...prev.address };

      data.address = {
        ...address,
        country: e.label,
        countryCode: e.countryCode
      };

      return data;
    });
  };

  const creationType = (creationType: number) => {
    switch (creationType) {
      case CreationTypesEnum.EMAIL:
        return 'Email';
      case CreationTypesEnum.FACEBOOK:
        return 'Facebook';
      case CreationTypesEnum.APPLE:
        return 'Apple';
      case CreationTypesEnum.GOOGLE:
        return 'Google';
      case CreationTypesEnum.LINKED_IN:
        return 'Linked In';
      default:
        return '';
    }
  };

  const userRegistrationStatuses = (registrationStatus: number) => {
    switch (registrationStatus) {
      case RegistrationStatusEnum.EMAIL_VERIFICATION:
        return 'EMAIL VERIFICATION';
      case RegistrationStatusEnum.EMAIL_VERIFIED:
        return 'EMAIL VERIFIED';
      case RegistrationStatusEnum.PASSWORD_ADDED:
        return 'PASSWORD ADDED';
      case RegistrationStatusEnum.SOCIAL_NETWORK_CREATION:
        return 'SOCIAL NETWORK CREATION';
      case RegistrationStatusEnum.SOCIAL_NETWORK_REFERRER_ADDED:
        return 'SOCIAL NETWORK REFFERER ADDED';
      case RegistrationStatusEnum.TEAM_ADDING:
        return 'TEAM ADDING';
      case RegistrationStatusEnum.PROFILE_DETAILS_ADDED:
        return 'PROFILE DETAILS ADDED';
      case RegistrationStatusEnum.SOCIAL_NETWORK_PROFILE_DETAILS_ADDED:
        return 'SOCIAL NETWORK PROFILE DETAILS ADDED';
      case RegistrationStatusEnum.SOCIAL_NETWORK_TERMS_AND_CONDITIONS_ACCEPTED:
        return 'SOCIAL NETWORK TERMS AND CONDITIONS ACCEPTED';
      default:
        return '';
    }
  };

  return (
    <Form>
      <Row>
        <Col lg={12} className='justify-content-between d-flex mb-3'>
          <h4 className='mb-0'>Personal Details</h4>
          <Button color='primary' onClick={() => dispatch(toggleEditState())}>
            Edit user
          </Button>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='nameInput' className='form-label'>
              UserName
            </Label>
            <Input
              type='text'
              className='form-control'
              id='userNameInput'
              // placeholder='Enter your name'
              value={detailsToCompare?.username}
              // onChange={handleChangeInput}
              name='userName'
              disabled={true}
            />
          </div>
        </Col>

        <Col lg={3}>
          <Link to={`/users/${data?.referrerId}`}>
            <div className='mb-3'>
              <Label htmlFor='sponsorId' className='form-label'>
                Sponsor ID
              </Label>
              <Input
                type='text'
                className='form-control'
                id='sponsorId'
                disabled
                value={data?.referrerId}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </Link>
        </Col>

        <Col lg={3}>
          <Link to={`/users/${data?.parentId}`}>
            <div className='mb-3'>
              <Label htmlFor='parentId' className='form-label'>
                Parent ID
              </Label>
              <Input
                type='text'
                className='form-control'
                id='parentId'
                disabled
                value={data?.parentId}
              />
            </div>
          </Link>
        </Col>

        {editState && (
          <SeparatorWithButton
            noButton
            buttonText='Save User Details'
            onClick={handleUpdateUserDetails}
            disabled={
              detailsToCompare?.name === data?.name &&
              detailsToCompare?.surname === data?.surname
            }
          />
        )}
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='nameInput' className='form-label'>
              Name
            </Label>
            <Input
              type='text'
              className='form-control'
              id='nameInput'
              placeholder='Enter your name'
              value={detailsToCompare?.name}
              onChange={handleChangeInput}
              name='name'
              disabled={!editState}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='lastnameInput' className='form-label'>
              Last Name
            </Label>
            <Input
              type='text'
              className='form-control'
              id='lastnameInput'
              placeholder='Enter your lastname'
              value={detailsToCompare?.surname}
              onChange={handleChangeInput}
              name='surname'
              disabled={!editState}
            />
          </div>
        </Col>

        {editState && (
          <SeparatorWithButton
            buttonText='Save User Details'
            onClick={handleUpdateUserDetails}
            disabled={
              detailsToCompare?.name === data?.name &&
              detailsToCompare?.surname === data?.surname
            }
          />
        )}

        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='cityInput' className='form-label'>
              Address
            </Label>
            <Input
              type='text'
              className='form-control'
              placeholder='City'
              value={detailsToCompare?.address?.addressLine1}
              onChange={handleChangeInputAddress}
              name={'addressLine1'}
              disabled={!editState}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='cityInput' className='form-label'>
              City
            </Label>
            <Input
              type='text'
              className='form-control'
              id='cityInput'
              placeholder='City'
              value={detailsToCompare?.address?.city}
              onChange={handleChangeInputAddress}
              name={'city'}
              disabled={!editState}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='countryInput' className='form-label'>
              Country
            </Label>

            <Select
              options={countryList}
              placeholder='Select Country'
              onChange={handleChangeCountry}
              value={selectedCountry}
              isDisabled={!editState}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='zipcodeInput' className='form-label'>
              Country Code
            </Label>

            <Input
              type='text'
              className='form-control'
              id='zipcodeInput'
              placeholder='Enter country code'
              value={selectedCountry.countryCode}
              onChange={handleChangeInputAddress}
              name={'countryCode'}
              disabled
            />
          </div>
        </Col>

        {editState && (
          <SeparatorWithButton
            buttonText='Save User Details'
            onClick={handleUpdateAddress}
            disabled={
              detailsToCompare?.address?.addressLine1 ===
                data?.address?.addressLine1 &&
              detailsToCompare?.address?.city === data?.address?.city &&
              selectedCountry.countryCode === data?.address?.countryCode &&
              detailsToCompare?.address?.country === data?.address?.country
            }
          />
        )}

        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='emailInput' className='form-label'>
              Email Address
            </Label>
            <Input
              type='email'
              className='form-control'
              id='emailInput'
              placeholder='Enter your email'
              onChange={handleChangeInput}
              value={detailsToCompare?.email}
              name='email'
              disabled={!editState}
            />
          </div>
        </Col>

        {editState && (
          <SeparatorWithButton
            buttonText='Save User Email'
            onClick={handleUpdateUserEmail}
            disabled={detailsToCompare?.email === data?.email}
          />
        )}

        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='dateCreated' className='form-label'>
              Joining Date
            </Label>

            <Input
              type='text'
              className='form-control'
              id='dateCreated'
              value={handleValidDate(data?.dateCreated)}
              disabled
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='userRank' className='form-label'>
              Rank
            </Label>
            <Input
              type='text'
              className='form-control'
              id='userRank'
              disabled
              value={data?.userPackage?.packageRank?.name}
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className='mb-3'>
            <Label htmlFor='userRank' className='form-label'>
              Qualified Rank
            </Label>
            <Input
              type='text'
              className='form-control'
              id='userRank'
              disabled
              value={qualifiedRank}
            />
          </div>
        </Col>

        <Col lg={12}>
          <div className='mb-3 mt-3'>
            User registered via: {creationType(data?.creationType)}
          </div>
        </Col>

        <Col lg={12}>
          <div className='mb-3'>
            {' '}
            User registration status: {data?.registrationStatus}
            {' - '}
            {userRegistrationStatuses(data?.registrationStatus)}
          </div>
        </Col>
      </Row>
    </Form>
  );
};
