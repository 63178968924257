import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import {
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
  noPagination?: boolean;
}

export const Filters: FC<FiltersProps> = ({
  params,
  setParams,
  noPagination
}) => {
  const filterInputs = [{ param: '', type: INPUT_TYPE_DATE_RANGE }];

  return (
    <>
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
        noPagination={noPagination}
      />
    </>
  );
};
