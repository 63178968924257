import moment from 'moment';
import { FC, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { formatUSDNoDecimals } from '../../../../../helpers/helpers';
import { HubsTradingPackage } from '../../types';
import { PackageConverted } from './PackageDisabled';

import { useAppSelector } from '../../../../../app/hooks';

export const TravelHub: FC<any> = ({ data }: { data: HubsTradingPackage }) => {
  const { data: userData } = useAppSelector((state) => state.userDetails);

  return (
    <div
      className='d-flex mb-3 mt-2 border border-primary'
      style={{ position: 'relative' }}>
      <img width={200} src={data.image} />
      <div className='d-flex  w-100 align-items-center'>
        <div className=' p-3  w-100'>
          <h4>{data?.name}</h4>
          <Row>
            <Col lg={3} className=' border-end p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>PRICE</h5>
              <h4 className='fs-15 mb-0'>{data.priceToShow}</h4>
            </Col>
            <Col lg={3} className=' border-end p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>
                DATE OF PURCHASE
              </h5>
              <h4 className='fs-15 mb-0'>
                {moment(data?.dateOfPurchase).format('DD MMM YYYY')}
              </h4>
            </Col>
            <Col lg={3} className=' p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>
                SUBSCRIPTION VOLUME
              </h5>
              <h4 className='fs-15 mb-0'>
                {formatUSDNoDecimals(data.subscriptionVolume)}
                <span className='text-muted fs-13'> SV</span>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={3} className='p-3'>
              <p className='mb-0'>TRAVELUTION MEMBERSHIP DETAILS</p>
            </Col>
            <Col lg={3} className='p-3'>
              <p className='mb-0'>
                ORDER ID: <b>{data.id}</b>
              </p>
            </Col>
            <Col lg={3} className='p-3'>
              <p className='mb-0'>
                PACKAGE ID: <b>{data.packageId}</b>
              </p>
            </Col>
          </Row>
          {/* 
          {userData?.address?.country === 'Japan' &&
            data?.isMemeBonusActivated && (
              <Row>
                <Col lg={3} className=' border-end p-3  mt-2'>
                  <h5 className='text-muted text-uppercase fs-13'>
                    DATE OF PURCHASE
                  </h5>
                  <h4 className='fs-15 mb-0'>
                    {moment(data?.dateFinupBonus).format('DD MMM YYYY')}
                  </h4>
                </Col>
                <Col lg={3} className=' border-end p-3  mt-2'>
                  <h5 className='text-muted text-uppercase fs-13'>PRICE</h5>
                  <h4 className='fs-15 mb-0'>
                    {formatUSDNoDecimals(data.memeBonusActivationPrice)}
                    <span className='text-muted fs-13'> USDT</span>
                  </h4>
                </Col>
                <Col lg={3} className=' p-3  mt-2'>
                  <h5 className='text-muted text-uppercase fs-13'>
                    MEME COINS GENERATED
                  </h5>
                  <h4 className='fs-15 mb-0'>
                    {formatUSDNoDecimals(data.price)}
                    <span className='text-muted fs-13'> PER DAY</span>
                  </h4>
                </Col>
              </Row>
            )} */}

          {/* {userData?.address?.country !== 'Japan' &&
            data.isMemeBonusActivated && (
              <Row>
                <Col lg={3} className=' border-end p-3  mt-2'>
                  <h5 className='text-muted text-uppercase fs-13'>
                    DATE OF PURCHASE
                  </h5>
                  <h4 className='fs-15 mb-0'>
                    {moment(data?.dateFinupBonus).format('DD MMM YYYY')}
                  </h4>
                </Col>

                <Col lg={3} className=' p-3  mt-2'>
                  <h5 className='text-muted text-uppercase fs-13'>
                    MEME COINS GENERATED
                  </h5>
                  <h4 className='fs-15 mb-0'>
                    {formatUSDNoDecimals(data.price)}
                    <span className='text-muted fs-13'> PER DAY</span>
                  </h4>
                </Col>
              </Row>
            )} */}
        </div>
      </div>
      <PackageConverted data={data} />
    </div>
  );
};
