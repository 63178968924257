import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'OID',
    accessor: 'oid',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Price',
    accessor: 'price',
    Cell: (cell: any) => {
      return <div>{cell?.value} USDT</div>;
    }
  },

  {
    id: 3,
    Header: 'Total Coins',
    accessor: 'totalCoins',
    Cell: (cell: any) => {
      return <div>{cell?.value} Willy</div>;
    }
  },

  {
    id: 4,
    Header: 'Date Paid',
    accessor: 'datePaid',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },
  {
    id: 5,
    Header: 'Activation Date',
    accessor: 'dateFinupBonus',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
