import { Accordion, AccordionItem, Collapse, Row } from 'reactstrap';

import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import {
  formatDecimalNumber,
  formatUSD
} from '../../../../../../helpers/helpers';
import { getUserHubsGamingInfo } from '../../../slice';
import { GamingHub } from '../GamingHub';
import { Tile } from '../../../../../../Components/Custom/Tile';

export const Gaming = () => {
  const { gaming } = useAppSelector((state) => state.userDetails);

  const [col, setCol] = useState(true);

  const t_col = () => {
    setCol(!col);
  };

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    if (gaming !== null) return;
    dispatch(getUserHubsGamingInfo(Number(userId)));
  }, []);

  return (
    <div>
      {' '}
      <h5>My Gaming HUB NFTs summary</h5>
      <Row>
        <Tile label='TOTAL GAMING HUB FT ORDERS'>
          {formatUSD(gaming?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='REVENUE SHARE POINTS'>
          {formatUSD(gaming?.summary?.totalRsp)}{' '}
          <span className='text-muted fs-14'>RSP</span>
        </Tile>
        <Tile label='GAMING HUB REWARDS (RSP)'>
          {formatDecimalNumber(
            gaming?.summary?.availableUsdtToClaim
              ? gaming?.summary?.availableUsdtToClaim
              : 0
          )}
          <span className='text-muted fs-14'> uUSDT</span>
        </Tile>
      </Row>
      <Accordion open={'2'}>
        <AccordionItem>
          <h2 className='accordion-header' id='headingTwo'>
            <button
              className={classnames('accordion-button', { collapsed: !col })}
              type='button'
              onClick={t_col}
              style={{ cursor: 'pointer' }}>
              Gaming HUB NFTs
            </button>
          </h2>
          <Collapse isOpen={col} className='accordion-collapse'>
            <div className='accordion-body'>
              {gaming?.userPackages?.map((data, i) => (
                <GamingHub key={i} data={data} />
              ))}
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
