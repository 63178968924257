import axios from 'axios';
import { api } from '../config';
import {
  getAccessToken,
  getRefreshToken,
  setNewAccessToken
} from './localstorage-helper';

const refreshToken = getRefreshToken();
const accessToken = getAccessToken();

export const axiosInstance = axios.create({
  baseURL: api.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const axiosAuthInstance = axios.create({
  baseURL: api.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// default
let token = accessToken ? accessToken : null;

if (token)
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;

// intercepting to capture errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 400 &&
      error.response.data.error === `"refreshToken" is required`
    ) {
      window.location.href = '/login';
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === '/authorization/token/refresh'
    ) {
      if (
        error.response.data.error ===
        'Refresh token is expired or not valid, please signin again!'
      ) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return axiosInstance
        .put('/authorization/token/refresh', { refreshToken })
        .then((res) => {
          if (res.status === 200) {
            setNewAccessToken(res.data.data.accessToken);

            originalRequest.headers['Authorization'] =
              'Bearer ' + res.data.data.accessToken;

            axiosInstance.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.data.accessToken;

            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export const setAuthorization = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};
