import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Pagination } from '../../../Components/Custom/pagination';
import { columns } from './columns';

import { getAirdropsById } from './slice';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { setDocumentTitle } from '../../../helpers/helpers';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';

const PAGE_TITLE = 'Airdrops';

const AirdropsPage = () => {
  const [id, setId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const dispatch = useAppDispatch();

  const { airdrops, loading } = useAppSelector((state) => state.airdrops);

  useEffect(() => {
    if (userId) {
      dispatch(getAirdropsById(userId));
    }
  }, [userId]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardHeader>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearchParams({ id });
                  }}>
                  <Row>
                    <Col md={3} sm={4}>
                      <SearchInputFilter
                        onChange={(e: any) => setId(e.target.value)}
                        value={id}
                        placeholder='Search User ID'
                      />
                    </Col>
                    <Col md={3} sm={4}>
                      <SearchSubmitButton />
                    </Col>
                  </Row>
                </form>
              </CardHeader>
              <CardBody className='pt-0'>
                <TableContainer
                  columns={columns}
                  data={airdrops}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                {/* <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AirdropsPage;
