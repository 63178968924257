import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { CsvExport } from '../../../Components/Custom/CsvExport';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  handleMultipleModalsWithOneState,
  paramsForExport,
  setDocumentTitle
} from '../../../helpers/helpers';
import { columns } from './columns';
import { Filters } from './filters';
import {
  exportHeaders,
  exportWithdrawals,
  getWithdrawals,
  markAsAutomaticPayout,
  removeWithdrawalsForExport,
  setShowActionButtons
} from './slice';
import { getUserFromLS } from '../../../helpers/localstorage-helper';
import { AreYouSureModal } from '../../../Components/Custom/AreYouSureModal';
import { OPENED_MODAL } from './types';

const PAGE_TITLE = 'Withdrawals';

const INITIAL_PARAMS: {
  sortBy: string;
  sortOrder: string;
  countPerPage: string;
  numberOfPage: string;
} = {
  sortBy: 'dateCreated',
  sortOrder: 'DESC',
  countPerPage: '10',
  numberOfPage: '1'
};

const WithdrawalsPage = () => {
  const [isOpen, setIsOpen] = useState(0);

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const {
    loading,
    data,
    totalCount,
    loadingExport,
    dataForExport,
    showActionButtons
  } = useAppSelector((state) => state.withdrawals);

  const isAnyChecked = data.some((item) => item.checked);

  const checkedItems = data.filter((item) => item.checked === true);

  const idsArray = checkedItems.map((item) => item.id);

  const userFromLS = getUserFromLS();

  const permissions = userFromLS?.permissions;

  useEffect(() => {
    if (checkedItems.length > 10) {
      toast.error('The maximum payout is 10');
    }
  }, [checkedItems]);

  const renderActionButton = () => {
    if (
      (permissions?.withdrawals && !permissions?.withdrawalsViewOnly) ||
      (permissions?.withdrawals && permissions?.withdrawalsViewOnly)
    ) {
      return (
        <div className='d-flex align-items-center'>
          <button
            type='button'
            style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}
            className='btn btn-primary'
            onClick={() => setIsOpen(OPENED_MODAL.AUTOMATIC_PAYOUT)}
            disabled={!isAnyChecked || checkedItems.length > 10}>
            Pay-out selected
          </button>

          <div
            style={{
              border: '1px solid #CED4D9',
              borderRadius: 4,
              height: 37.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 250
            }}>
            <div style={{ marginRight: 30 }}>Multiple Off</div>
            <div className='flex mt-1'>
              <div className='form-check form-switch form-switch-right form-switch-md'>
                <input
                  className='form-check-input code-switcher'
                  type='checkbox'
                  id='toggle-button'
                  checked={showActionButtons}
                  onChange={() =>
                    dispatch(setShowActionButtons(showActionButtons))
                  }
                />
              </div>
            </div>
            <div style={{ marginLeft: 30 }}>Multiple On</div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  useEffect(() => {
    dispatch(getWithdrawals(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading || loadingExport}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={
                  <>
                    {renderActionButton()}

                    <CsvExport
                      action={() =>
                        dispatch(
                          exportWithdrawals(paramsForExport(searchParams))
                        )
                      }
                      data={dataForExport}
                      headers={exportHeaders}
                      disabled={loadingExport}
                      fileName={`withdrawals-${moment().format('DD-MM-YYYY')}`}
                      removeDataAction={removeWithdrawalsForExport}
                    />
                    <AreYouSureModal
                      isOpen={isOpen === OPENED_MODAL.AUTOMATIC_PAYOUT}
                      title='Mark for automatic payout'
                      setIsOpen={() =>
                        handleMultipleModalsWithOneState(
                          OPENED_MODAL.AUTOMATIC_PAYOUT,
                          setIsOpen
                        )
                      }
                      onSubmit={() => dispatch(markAsAutomaticPayout(idsArray))}
                    />
                  </>
                }
              />
              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />
                <div className='d-flex justify-content-end'></div>

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WithdrawalsPage;
