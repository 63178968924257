/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import {
  apiChangePassword,
  apiSignIn,
  confirmTwoStepVerificationApi,
  enableTwoStepVerificationApi
} from '../../../api/auth.api';
import { setLoginDataToLS } from '../../../helpers/localstorage-helper';
import { axiosInstance, setAuthorization } from '../../../helpers/api_helper';
import React, { RefAttributes } from 'react';
import { AuthCodeProps, AuthCodeRef } from 'react-auth-code-input';

interface HandleSignInProps {
  navigate: any;
  email: string;
  password: string;
  twoFaCode: string;
  setTwoFaCode: React.Dispatch<React.SetStateAction<string>>;
  setTwoFaModal: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAddTwoFaModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTwoFaData: any;
  setChangePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const handleSignIn = async ({
  navigate,
  email,
  password,
  twoFaCode,
  setTwoFaCode,
  setTwoFaModal,
  setLoading,
  setAddTwoFaModal,
  setTwoFaData,
  setChangePasswordModal
}: HandleSignInProps): Promise<void> => {
  try {
    setLoading(true);

    const { data } = await apiSignIn({
      email,
      password,
      twoFACode: twoFaCode ? twoFaCode : null
    });

    setLoading(false);

    if (data?.data?.token) {
      //   const permissions = data.data.user.permissions;
      //    permissionsNavigate(permissions,navigate)

      setAuthorization(data.data.token.accessToken);

      setLoginDataToLS(data.data);

      navigate('/orders');
    }
  } catch (error: any) {
    setLoading(false);
    const res = error?.response?.data;

    if (
      res?.error ===
      '2FA verification code is not valid, please generate new code.'
    ) {
      toast.error(res?.error);
      return;
    }

    if (res?.error === 'User account is deactivated.') {
      toast.error(res?.error);
      return;
    }

    if (res?.error === 'Incorrect email or password') {
      toast.error(res?.error);
      return;
    }
    if (res?.error === 'Please send 2FA verification code.') {
      setTwoFaModal(true);
    } else if (res?.data?.shouldAddTwoFA && !res?.data?.shouldChangePassword) {
      setAddTwoFaModal(true);
      setTwoFaData(res?.data);
    } else if (res?.data?.shouldChangePassword) {
      setChangePasswordModal(true);
      setTwoFaData(res?.data);
    } else {
      setTwoFaCode('');
    }
  }
};

//   const permissionsNavigate = (permissions, navigate) => {
//     if (permissions.users) {
//         navigate('/users');
//       } else if (permissions.packageOrders) {
//         navigate('/package-orders');
//       } else if (permissions.ranking) {
//         navigate('/package-ranks');
//       } else if (permissions.rankRewards) {
//         navigate('/package-rewards');
//       } else if (permissions.withdrawals) {
//         navigate('/withdrawals');
//       } else if (permissions.director) {
//         navigate('/director-info');
//       } else if (permissions.superAdmin) {
//         navigate('/super-admin');
//       } else if (permissions.teams) {
//         navigate('/my-team');
//       } else if (permissions.bonuses) {
//         navigate('/team-bonus');
//       } else if (permissions.shop) {
//         navigate('/product-categories');
//       } else if (permissions.campaign) {
//         navigate('/campaign-categories');
//       } else if (permissions.promotions) {
//         navigate('/promotions');
//       } else if (permissions.contentBlocks) {
//         navigate('/content-blocks');
//       } else if (permissions.events) {
//         navigate('/events');
//       } else if (permissions.eventTickets) {
//         navigate('/event-tickets');
//       } else if (permissions.helpDesk) {
//         navigate('/chat');
//       } else if (permissions.news) {
//         navigate('/news');
//       } else if (permissions.faq) {
//         navigate('/categories');
//       }
//   }

interface ChangePasswordProps {
  twoFaData: any;
  newPassword: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const changePassword = async ({
  twoFaData,
  newPassword,
  setIsOpen
}: ChangePasswordProps): Promise<void> => {
  try {
    await apiChangePassword(twoFaData, newPassword);

    setIsOpen(false);
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};

interface EnableTwoStepVerificationProps {
  twoFaData: any;
  setEnableStep: React.Dispatch<React.SetStateAction<number>>;
  setUser2FAData: React.Dispatch<React.SetStateAction<any | null>>;
}

export const enableTwoStepVerification = async ({
  twoFaData,
  setEnableStep,
  setUser2FAData
}: EnableTwoStepVerificationProps): Promise<void> => {
  try {
    const { data } = await enableTwoStepVerificationApi(twoFaData);
    setEnableStep(2);
    setUser2FAData(data.data);
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};

interface ConfirmTwoStepVerificationProps {
  twoFaData: any;
  code: any;
  setEnableStep: React.Dispatch<React.SetStateAction<number>>;
}

export const confirmTwoStepVerification = async ({
  twoFaData,
  code,
  setEnableStep
}: ConfirmTwoStepVerificationProps): Promise<void> => {
  try {
    await confirmTwoStepVerificationApi(twoFaData, code);
    setEnableStep(4);
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};
