import React from 'react';

const Footer = () => {
  return (
    <React.Fragment>
      {/* <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © Ultron.
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by Themesbrand
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer> */}
    </React.Fragment>
  );
};

export default Footer;
