import { useState } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AreYouSureModal } from '../../../../Components/Custom/AreYouSureModal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { handleMultipleModalsWithOneState } from '../../../../helpers/helpers';
import { updateAutomaticPayment, updateWithdrawalStatus } from '../slice';
import { OPENED_MODAL, WithdrawalStatusEnum, Withdrawals } from '../types';

import { getUserFromLS } from '../../../../helpers/localstorage-helper';
import { Toggle } from '../../../../Components/Custom/Toggle';

export const AutomaticPayout = (cell: any) => {
  const dispatch = useAppDispatch();

  const { id, status }: Withdrawals = cell.row.original;

  const userFromLS = getUserFromLS();

  const permissions = userFromLS?.permissions;

  const { data } = useAppSelector((state) => state.withdrawals);

  const checkedItems = data.filter((item) => item.checked === true);

  const renderActionButton = () => {
    if (permissions?.withdrawals && permissions?.withdrawalsViewOnly) {
      return (
        <div className='d-flex justify-content-center'>
          <Toggle
            checked={cell.value}
            name='payout'
            onChange={() => dispatch(updateAutomaticPayment({ id }))}
          />
        </div>
      );
    } else if (permissions?.withdrawals && !permissions?.withdrawalsViewOnly) {
      return (
        <div className='d-flex justify-content-center'>
          <Toggle
            checked={cell.value}
            name='payout'
            onChange={() => dispatch(updateAutomaticPayment({ id }))}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      {status === WithdrawalStatusEnum.PENDING && <>{renderActionButton()}</>}
    </>
  );
};

export default AutomaticPayout;
