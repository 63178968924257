import { Navigate } from 'react-router-dom';

//login
import Login from '../pages/Authentication/Login/Login';

//dashboard
import TaskList from '../pages/Dashboard/TaskList';
import PackageOrdersPage from '../pages/Dashboard/PackageOrders';

import PackageRankHistoryPage from '../pages/Dashboard/RankHistory';
import PackageRankRewardPage from '../pages/Dashboard/RankReward';
import WithdrawalsPage from '../pages/Dashboard/Withdrawals';
import ConversionsPage from '../pages/Dashboard/Conversions';
import BlackistedWalletsPage from '../pages/Dashboard/BlackistedWallets';
import BlacklistedUsersPage from '../pages/Dashboard/BlacklistedUsers';
import PerformanceIDs from '../pages/Dashboard/PerformanceIDs';
import UsersPage from '../pages/Dashboard/Users';
import TeamBonusPage from '../pages/Bonuses/TeamBonus';
import FreeWillyCoinsPage from '../pages/Dashboard/FreeWillyCoins';
import TradingTeamBonusPage from '../pages/Bonuses/TradingTeamBonus';
import DirectBonusPage from '../pages/Bonuses/DirectBonus';
import MatchingBonusPage from '../pages/Bonuses/MatchingBonus';
import TotalBonusPage from '../pages/Bonuses/TotalBonus';
import CustomerBonusPage from '../pages/Bonuses/CustomerBonus';
import FastStartBonusPage from '../pages/Bonuses/FastStartBonus';
import GlobalPoolBonusPage from '../pages/Bonuses/GlobalPoolBonus';
import TopPerformerBonusPage from '../pages/Bonuses/TopPerformerBonus';
import DirectorInfoPage from '../pages/Dashboard/DirectorInfo';
import BalanceChange from '../pages/Dashboard/BalanceChange';
import SuperAdminPage from '../pages/Dashboard/SuperAdmin';
import UserDetailsPage from '../pages/Dashboard/UserDetails';
import TeamsPage from '../pages/Dashboard/Teams';
import ContentBlocksPage from '../pages/Dashboard/ContentBlocks';
import LicensesPage from '../pages/Dashboard/Licenses';
import Faq from '../pages/Faq';
import FastStartBonusPreviewPage from '../pages/Bonuses/FastStartBonusPreview';
import EventTickets from '../pages/Dashboard/EventTickets';
import AirdropsPage from '../pages/Dashboard/Airdrops/Airdrops';

export const routes = [
  // Dashboard pages
  { path: '/task-list', component: <TaskList /> },
  { path: '/director-info', component: <DirectorInfoPage /> },
  { path: '/balance-change', component: <BalanceChange /> },
  { path: '/super-admin', component: <SuperAdminPage /> },
  { path: '/users', component: <UsersPage /> },
  { path: '/users/:id/*', component: <UserDetailsPage /> },
  { path: '/package-orders', component: <PackageOrdersPage /> },
  { path: '/airdrops', component: <AirdropsPage /> },

  { path: '/package-rank-history', component: <PackageRankHistoryPage /> },
  { path: '/package-rank-rewards', component: <PackageRankRewardPage /> },
  { path: '/event-tickets', component: <EventTickets /> },
  { path: '/withdrawals', component: <WithdrawalsPage /> },
  { path: '/conversions', component: <ConversionsPage /> },
  { path: '/blacklisted', component: <BlackistedWalletsPage /> },
  { path: '/blacklisted-users', component: <BlacklistedUsersPage /> },
  { path: '/performance', component: <PerformanceIDs /> },
  { path: '/teams', component: <TeamsPage /> },
  { path: '/willy-coins', component: <FreeWillyCoinsPage /> },
  // { path: '/earnings', component: <EarningsPage /> },
  { path: '/content-blocks', component: <ContentBlocksPage /> },
  { path: '/licenses', component: <LicensesPage /> },
  { path: '/team-bonus', component: <TeamBonusPage /> },
  { path: '/trading-team-bonus', component: <TradingTeamBonusPage /> },
  { path: '/direct-bonus', component: <DirectBonusPage /> },
  { path: '/matching-bonus', component: <MatchingBonusPage /> },
  { path: '/total-bonus', component: <TotalBonusPage /> },
  { path: '/customer-bonus', component: <CustomerBonusPage /> },
  { path: '/fast-start-bonus', component: <FastStartBonusPage /> },
  {
    path: '/fast-start-bonus/:id/:cashbackBonusId?',
    component: <FastStartBonusPreviewPage />
  },
  { path: '/global-pool-bonus', component: <GlobalPoolBonusPage /> },
  { path: '/top-performer-bonus', component: <TopPerformerBonusPage /> },
  { path: '/faq', component: <Faq /> },
  {
    path: '/',
    exact: true,
    component: <Navigate to='/task-list' />
  },
  { path: '*', component: <Navigate to='/task-list' /> }
];

export const authRoutes = [
  // Authentication Pages
  { path: '/login', component: <Login /> }
];
