import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { DirectorInfo, DirectorInfoState } from './types';
import moment from 'moment';

export const getDirectorInfo = createAsyncThunk(
  'directorInfo/fetch',
  async (params: URLSearchParams) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get('/director/info', {
      params: plainParams
    });
    return response.data.data;
  }
);

const initialState = {
  data: {},
  totalCount: 0,
  loading: false
} as DirectorInfoState;

const DirectorInfoSlice = createSlice({
  name: 'directorInfo',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectorInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDirectorInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.info as DirectorInfo;
      state.totalCount = payload.count;
    });
    builder.addCase(getDirectorInfo.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default DirectorInfoSlice.reducer;
