import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { AirdropsTokensState, AirdropsTokens } from './types';
import { toast } from 'react-toastify';

export const getAirdropsById = createAsyncThunk(
  'getAirdrops/fetchById',
  async (id: string) => {
    const response = await axiosInstance.get(`/airdrop/tokens/${id}`);

    return response?.data?.data;
  }
);

const initialState = {
  airdrops: [],
  hasActiveLicence: false,
  totalCount: 0,
  loading: false
} as AirdropsTokensState;

const airdropsSlice = createSlice({
  name: 'airdropsSlice',
  initialState,
  reducers: {
    ordered: (state) => {
      state.loading = true;
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getAirdropsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAirdropsById.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.hasActiveLicence = payload.hasActiveLicence;
      state.airdrops = payload.tokens;
    });
    builder.addCase(getAirdropsById.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default airdropsSlice.reducer;
