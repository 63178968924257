import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import {
  INPUT_TYPE_AMOUNT,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
  colLg?: number;
  colMd?: number;
  colSm?: number;
}

export const Filters: FC<FiltersProps> = ({
  params,
  setParams,
  colLg,
  colMd,
  colSm
}) => {
  const statusOptions = [
    { label: 'Pending', value: '1' },
    { label: 'Paid', value: '2' }
  ];

  const earningsTypeOptions = [
    { label: 'Team Bonus Bonus', value: '1' },
    { label: 'Direct Bonus', value: '2' },
    { label: 'Matching Bonus', value: '3' },
    { label: 'Customer Bonus', value: '4' },
    { label: 'Cashback Bonus', value: '5' },
    { label: 'Global Pool Bonus', value: '6' },
    { label: 'Top Performer Bonus', value: '7' },
    { label: 'Package Rank Reward', value: '8' },
    { label: 'Hodl Pool Bonus', value: '9' },
    { label: 'My Life Points', value: '10' },
    { label: 'Trading Bonus', value: '11' },
    { label: 'Loyality Points', value: '12' }
  ];

  const sortOrderOptions = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }
  ];

  const sortByOptions = [
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Amount', value: 'amount' }
  ];

  const sortingInputs = [
    { param: 'sortBy', type: INPUT_TYPE_SELECT, options: sortByOptions },
    { param: 'sortOrder', type: INPUT_TYPE_SELECT, options: sortOrderOptions }
  ];

  const filterInputs = [
    { param: 'id', type: INPUT_TYPE_SEARCH, placeholder: 'Search Earnings ID' },
    {
      param: 'earningsTypes',
      type: INPUT_TYPE_SELECT,
      placeholder: 'Earning Type',
      options: earningsTypeOptions
    },

    { param: '', type: INPUT_TYPE_DATE_RANGE },
    {
      param: 'status',
      type: INPUT_TYPE_SELECT,
      placeholder: 'Status',
      options: statusOptions
    },
    {
      param: 'fromAmount',
      type: INPUT_TYPE_AMOUNT,
      placeholder: 'From amount'
    },
    { param: 'toAmount', type: INPUT_TYPE_AMOUNT, placeholder: 'To Amount' }
  ];

  return (
    <>
      <RenderSorting
        sortingInputs={sortingInputs}
        searchParams={params}
        setSearchParams={setParams}
        colLg={colLg}
        colMd={colMd}
        colSm={colSm}
      />
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
        colLg={colLg}
        colMd={colMd}
        colSm={colSm}
      />
    </>
  );
};
