import { useEffect } from 'react';
import { Route, Routes, useMatch } from 'react-router';
import { Col, Nav, NavItem, Row, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getUserHubsSummary } from '../../slice';

import { NavLink } from 'react-router-dom';

import { FinUp } from './sections/FinUp/FinUp';
import { Bring3Activations } from './sections/Bring3Activations/Bring3Activations';

const nestedPages = [
  {
    name: 'FinUp',
    route: 'finup',
    component: FinUp
  },

  {
    name: 'Bring 3 activations',
    route: 'bring3activations',
    component: Bring3Activations
  }
];

export const MemeCoins = () => {
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.userDetails);

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    dispatch(getUserHubsSummary(Number(userId)));
  }, []);

  return (
    <div>
      <Nav
        className='nav-tabs-custom rounded  border-bottom-0 mb-4 mt-4'
        role='tablist'>
        {nestedPages.map((page, i) => (
          <NavItem key={i}>
            <NavLink to={page.route} className='nav-link'>
              <i className='fas fa-home'></i>
              {page.name}
            </NavLink>
          </NavItem>
        ))}

        {loading && (
          <div className='d-flex align-items-center'>
            <Spinner size='sm' className='primary' />
          </div>
        )}
      </Nav>

      <Routes>
        {nestedPages.map((page, i) => (
          <Route key={i} path={page.route} element={<page.component />} />
        ))}
      </Routes>
    </div>
  );
};
