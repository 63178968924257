import { Accordion, AccordionItem, Collapse, Row } from 'reactstrap';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { formatUSD } from '../../../../../../helpers/helpers';
import { getTravelInfo } from '../../../slice';
import { Tile } from '../../../../../../Components/Custom/Tile';
import { TravelHub } from '../TravelHub';

export const Travel = () => {
  const { loading, travel } = useAppSelector((state) => state.userDetails);

  const [col, setCol] = useState(true);

  const t_col = () => {
    setCol(!col);
  };

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    if (travel !== null) return;
    dispatch(getTravelInfo(Number(userId)));
  }, [travel]);

  return (
    <div>
      <h5>My Travelution Membership Summary</h5>
      <Row>
        <Tile label='Total Travelution orders'>
          {formatUSD(travel?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='SUBSCRIPTION VOLUME'>
          {formatUSD(travel?.summary?.subscriptionVolume)}{' '}
          <span className='text-muted fs-14'>SV</span>
        </Tile>
      </Row>
      {/* <Row>
        <Tile label='TOTAL WILLY MEME COIN COLLECTION'>
          {formatUSD(travel?.summary?.totalMemeCoins)}
          <span className='text-muted fs-14'> WILLY</span>
        </Tile>
        <Tile label='WILLY COLLECTED FROM BIGWHALE STRATEGYs'>
          {formatUSD(travel?.summary?.memeCoinsFromBigWhale)}{' '}
          <span className='text-muted fs-14'>WILLY</span>
        </Tile>
        <Tile label='WILLY COLLECTED FROM "BRING 3 PROMOTION"'>
          {formatUSD(bigWhale?.summary?.memeCoinsFromBring3)}{' '}
          <span className='text-muted fs-14'>WILLY</span>
        </Tile>
      </Row> */}
      <Accordion open={'3'}>
        <AccordionItem>
          <h2 className='accordion-header' id='headingTwo'>
            <button
              className={classnames('accordion-button', { collapsed: !col })}
              type='button'
              onClick={t_col}
              style={{ cursor: 'pointer' }}>
              My Travelution Memberships
            </button>
          </h2>
          <Collapse isOpen={col} className='accordion-collapse'>
            <div className='accordion-body'>
              {travel?.userPackages?.map((data, i) => (
                <TravelHub key={i} data={data} />
              ))}
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
