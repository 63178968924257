import './Bring3Activations.css';
import { useMemo, FC, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../../../app/hooks';

import { useMatch } from 'react-router';

import moment from 'moment';
import { getBring3Activations } from '../../../../slice';
import { Row, Spinner } from 'reactstrap';
import { H1 } from '../../../../../../../styles/FontStyles';
import { Tile } from '../../../../../../../Components/Custom/Tile';
import {
  formatUSD,
  handleValidDate,
  handleValidTime
} from '../../../../../../../helpers/helpers';

export const Bring3Activations = () => {
  const dispatch = useAppDispatch();
  const { bring3Activations, loadingMemeCoins } = useAppSelector(
    (state) => state.userDetails
  );

  const match = useMatch('/users/:id/*');
  const id = match?.params.id;

  useEffect(() => {
    if (bring3Activations !== null) return;
    dispatch(getBring3Activations(Number(id)));
  }, [id]);

  const userId = bring3Activations?.totalCoinsForToday;

  return (
    <>
      {loadingMemeCoins ? (
        <div className='d-flex align-items-center'>
          <Spinner size='sm' className='primary' />
        </div>
      ) : (
        <>
          {' '}
          <Row>
            <Tile label='TOTAL COINS FOR TODAY'>
              <>{formatUSD(bring3Activations?.totalCoinsForToday)} </>
              <span className='text-muted fs-14'> WILLY</span>
            </Tile>

            <Tile label='TOTAL COINS TILL TODAY'>
              {formatUSD(bring3Activations?.totalCoinsTillToday)}{' '}
              <span className='text-muted fs-14'>WILLY</span>
            </Tile>
          </Row>
          <table className='activations_tbl'>
            <thead>
              <tr>
                <th className='activations_tbh'>coins</th>
                <th className='activations_tbh'>oid</th>
                <th className='activations_tbh'>price</th>
                <th className='activations_tbh'>date paid</th>
                <th className='activations_tbh'>activation date</th>
                <th className='activations_tbh'>first payout date</th>
                <th className='activations_tbh'>sales</th>
              </tr>
            </thead>
            <tbody>
              {bring3Activations?.activations?.map((activation, i) => {
                return (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: activation.poid ? 'lightgreen' : ''
                    }}>
                    <td
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        paddingLeft: 10
                      }}>
                      {formatUSD(activation.coins)}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {activation.poid ?? '---'}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {formatUSD(activation.poPrice) ?? '---'}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {activation.poDatePaid ? (
                        <>
                          {handleValidDate(activation.poDatePaid)},
                          <small className='text-muted'>
                            {' '}
                            {handleValidTime(activation.poDatePaid)}
                          </small>
                        </>
                      ) : (
                        '---'
                      )}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {activation.activationDate ? (
                        <>
                          {handleValidDate(activation.activationDate)},
                          <small className='text-muted'>
                            {' '}
                            {handleValidTime(activation.activationDate)}
                          </small>
                        </>
                      ) : (
                        '---'
                      )}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {activation.payoutDate ? (
                        <>
                          {handleValidDate(activation.payoutDate)},
                          <small className='text-muted'>
                            {' '}
                            {handleValidTime(activation.payoutDate)}
                          </small>
                        </>
                      ) : (
                        '---'
                      )}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                      {activation.directs.length > 0 ? (
                        <table
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 5
                          }}>
                          <thead>
                            <tr>
                              <th className='activations_tbh'>oid</th>
                              <th className='activations_tbh'>uid</th>
                              <th className='activations_tbh'>price</th>
                              <th className='activations_tbh'>date paid</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activation.directs.map((sale, i) => (
                              <tr key={i}>
                                <td className='activations_tbh'>
                                  {sale.directOid}
                                </td>
                                <td className='activations_tbh'>
                                  {sale.directUid}
                                </td>
                                <td className='activations_tbh'>
                                  {formatUSD(sale.directPrice)}
                                </td>
                                <td className='activations_tbh'>
                                  {sale.directDatePaid ? (
                                    <>
                                      {handleValidDate(sale.directDatePaid)},
                                      <small className='text-muted'>
                                        {' '}
                                        {handleValidTime(sale.directDatePaid)}
                                      </small>
                                    </>
                                  ) : (
                                    '---'
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        '---'
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
