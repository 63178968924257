import React, { FC, RefAttributes, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { confirmTwoStepVerification, enableTwoStepVerification } from './logic';
import { AuthCodeRef } from 'react-auth-code-input';

interface AddTwoStepVerificationModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  twoFaData: any;
  loading: boolean;
}

export const AddTwoStepVerificationModal: FC<
  AddTwoStepVerificationModalProps
> = ({ isOpen, setIsOpen, twoFaData, loading }) => {
  const [enableStep, setEnableStep] = useState(1);
  const [user2FAData, setUser2FAData] = useState<any | null>(null);
  const [code, setCode] = useState('');

  const setSteps = () => {
    switch (enableStep) {
      case 1:
        return 'Enable';
      case 2:
        return 'Next';
      case 3:
        return 'Confirm';
      case 4:
        return 'Close';

      default:
        return '';
    }
  };

  const handleOnChange = (res: any) => {
    setCode(res);
  };

  const onSubmitEnableTwoFa = async () => {
    enableTwoStepVerification({ twoFaData, setEnableStep, setUser2FAData });
  };

  const onSubmitConfirmTwoFa = async () => {
    confirmTwoStepVerification({ twoFaData, code, setEnableStep });
  };

  const onSubmit = () => {
    if (enableStep === 1) {
      onSubmitEnableTwoFa();
    } else if (enableStep === 2) {
      setEnableStep(3);
    } else if (enableStep === 3) {
      onSubmitConfirmTwoFa();
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className='modal-dialog-centered'>
      <ModalBody className='text-center'>
        <form className='auth-login-form' onSubmit={onSubmit}>
          <div className='mb-3'>
            <div className='avatar-lg mx-auto'>
              <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
                <i className='ri-error-warning-fill'></i>
              </div>
            </div>
          </div>

          <h4 className='mb-4'>
            {enableStep !== 4 && 'Enable 2FA verification'}
          </h4>
          {enableStep === 2 && (
            <div className='d-flex flex-column align-items-center'>
              <p className='mt-3'>Scan this QR Code in the Authenticator app</p>
              <div
                style={{
                  height: 200,
                  width: 200,
                  backgroundPosition: 'center',
                  backgroundImage: `url(${user2FAData?.user2FAData?.qrUrl})`,
                  backgroundRepeat: 'no-repeat'
                }}
              />
              <p className='mt-3'>{user2FAData?.user2FAData?.secret}</p>
            </div>
          )}
          {enableStep === 3 && (
            <div>
              <h2 className='text-left'>2FA verification code</h2>
              <div className='react-code-input'>
                <AuthCode
                  allowedCharacters='numeric'
                  inputClassName='col form-control form-control-lg bg-light border-light text-center m-2'
                  containerClassName='row'
                  length={6}
                  onChange={handleOnChange}
                  autoFocus={true}
                />
              </div>

              <p className='text-left'>
                Enter the 6 digit code from your 2FA authenticator
              </p>
            </div>
          )}

          {enableStep === 4 && (
            <p className='text-center'>
              You have successfully activated 2-FA Authenticator on your
              account.
            </p>
          )}

          <div className='mt-3'>
            <Button color='success' className='w-100' onClick={onSubmit}>
              {loading ? <Spinner size='sm' className='me-2' /> : null}
              {setSteps()}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
