import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

//import images
import { getUserFromLS } from '../../helpers/localstorage-helper';

const ProfileDropdown = () => {
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const user = getUserFromLS();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'>
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            {/* <img
              className='rounded-circle header-profile-user'
              src={avatar1}
              alt='Header Avatar'
            /> */}
            <span className='text-start'>
              <span className='ms-1 fw-medium user-name-text'>
                {user?.email}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <DropdownItem className='p-0'>
            <Link to={'/login'} className='dropdown-item'>
              <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
              <span className='align-middle' data-key='t-logout'>
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
