import { useState } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AreYouSureModal } from '../../../../Components/Custom/AreYouSureModal';
import { useAppDispatch } from '../../../../app/hooks';
import { handleMultipleModalsWithOneState } from '../../../../helpers/helpers';
import { markAsAutomaticPayout, updateWithdrawalStatus } from '../slice';
import { OPENED_MODAL, WithdrawalStatusEnum, Withdrawals } from '../types';
import { WithdrawalsDropdownItem } from './WithdrawalsDropdownItem';
import { getUserFromLS } from '../../../../helpers/localstorage-helper';

export const PackageOrdersActions = (cell: any) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(0);

  const { id, status }: Withdrawals = cell.row.original;

  const userFromLS = getUserFromLS();

  const permissions = userFromLS?.permissions;

  const renderActionButton = () => {
    if (permissions?.withdrawals && permissions?.withdrawalsViewOnly) {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            href='#'
            className='btn btn-soft-secondary btn-sm'
            tag='button'>
            <i className='ri-more-fill' />
          </DropdownToggle>

          <DropdownMenu className='dropdown-menu-end'>
            <>
              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as canceled'
                toOpen={OPENED_MODAL.CANCELED}
              />
              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as paid'
                toOpen={OPENED_MODAL.MARK_AS_PAID}
              />

              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as automatic payout'
                toOpen={OPENED_MODAL.AUTOMATIC_PAYOUT}
              />
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    } else if (permissions?.withdrawals && !permissions?.withdrawalsViewOnly) {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            href='#'
            className='btn btn-soft-secondary btn-sm'
            tag='button'>
            <i className='ri-more-fill' />
          </DropdownToggle>

          <DropdownMenu className='dropdown-menu-end'>
            <>
              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as canceled'
                toOpen={OPENED_MODAL.CANCELED}
              />
              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as paid'
                toOpen={OPENED_MODAL.MARK_AS_PAID}
              />

              <WithdrawalsDropdownItem
                setIsOpen={setIsOpen}
                title='Mark as automatic payout'
                toOpen={OPENED_MODAL.AUTOMATIC_PAYOUT}
              />
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      {status === WithdrawalStatusEnum.PENDING && (
        <>
          {renderActionButton()}
          <AreYouSureModal
            isOpen={isOpen === OPENED_MODAL.CANCELED}
            title='Mark as canceled'
            setIsOpen={() =>
              handleMultipleModalsWithOneState(OPENED_MODAL.CANCELED, setIsOpen)
            }
            onSubmit={() =>
              dispatch(
                updateWithdrawalStatus({
                  id,
                  type: WithdrawalStatusEnum.CANCELED
                })
              )
            }
          />
          <AreYouSureModal
            isOpen={isOpen === OPENED_MODAL.MARK_AS_PAID}
            title='Mark as paid'
            setIsOpen={() =>
              handleMultipleModalsWithOneState(
                OPENED_MODAL.MARK_AS_PAID,
                setIsOpen
              )
            }
            onSubmit={() =>
              dispatch(
                updateWithdrawalStatus({ id, type: WithdrawalStatusEnum.PAID })
              )
            }
          />

          <AreYouSureModal
            isOpen={isOpen === OPENED_MODAL.AUTOMATIC_PAYOUT}
            title='Mark for automatic payout'
            setIsOpen={() =>
              handleMultipleModalsWithOneState(
                OPENED_MODAL.AUTOMATIC_PAYOUT,
                setIsOpen
              )
            }
            onSubmit={() => dispatch(markAsAutomaticPayout([id]))}
          />
        </>
      )}
    </>
  );
};

export default PackageOrdersActions;
