import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../helpers/api_helper';
import { toast } from 'react-toastify';

export const sendPasswordOrId = createAsyncThunk(
  'userActions/sendPasswordOrId',
  async (
    { action, userId }: { action: string; userId: number },
    { rejectWithValue }
  ) => {
    const actionURL =
      action === 'id' ? 'forgotten-id' : 'forgotten-password/send';

    try {
      const response = await axiosInstance.post(`/user/mlm/${actionURL}`, {
        userId
      });

      toast.success(response.data.message);
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const resendVerificationMail = createAsyncThunk(
  'userActions/resendVerificationMail',
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/user/mlm/email/resend`, {
        userId
      });

      toast.success(response.data.message);
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const reset2Fa = createAsyncThunk(
  'userActions/reset2Fa',
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/user/mlm/2fa/reset`, {
        userId
      });

      toast.success(response.data.message);
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const setUserActivationStatus = createAsyncThunk(
  'userActions/setUserActivationStatus',
  async (
    { userId, activationStatus }: { userId: number; activationStatus: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/user/mlm/activation-status`, {
        userId,
        activationStatus
      });

      toast.success('Successfully changed user activation status');
      return response.data.data.activationStatus;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateUserType = createAsyncThunk(
  'userActions/updateUserType',

  async (
    { userId, userType }: { userId: number; userType: number },
    { rejectWithValue }
  ) => {
    try {
      await axiosInstance.put(`/user/mlm/user-type`, {
        userId,
        userType
      });
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);
