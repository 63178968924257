import React, { FC } from 'react';
import AuthCode from 'react-auth-code-input';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';

interface ChangePasswordModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (e: any) => void;
  setTwoFaCode: React.Dispatch<React.SetStateAction<string>>;
  twoFaCode: string;
  loading: boolean;
}

export const TwoStepVerificationModal: FC<ChangePasswordModalProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  setTwoFaCode,
  twoFaCode,
  loading
}) => {
  const handleOnChange = (res: any) => {
    setTwoFaCode(res);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className='modal-dialog-centered'>
      <ModalBody className='text-center'>
        <div className='mb-3'>
          <div className='avatar-lg mx-auto'>
            <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
              <i className='ri-mail-line'></i>
            </div>
          </div>
        </div>

        <div className='p-2 '>
          <div className='text-muted text-center mb-4 mx-lg-3'>
            <h4 className=''>Two factor verification</h4>
            <p>
              Please enter the 6 digit code
              {/* <span className='fw-semibold'>example@abc.com</span> */}
            </p>
          </div>

          <form onSubmit={(e) => onSubmit(e)}>
            <AuthCode
              allowedCharacters='numeric'
              inputClassName='col form-control form-control-lg bg-light border-light text-center m-2'
              containerClassName='row'
              length={6}
              onChange={handleOnChange}
              autoFocus={true}
            />

            <div className='mt-3'>
              <Button
                color='success'
                className='w-100'
                type='submit'
                disabled={Number(twoFaCode) < 6}>
                {loading ? <Spinner size='sm' className='me-2' /> : null}
                Confirm
              </Button>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
